import React from 'react';
import { Link, graphql } from 'gatsby';

import * as styles from './BlogPost.module.css';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const BlogPostTemplate: React.FC<any> = ({ data, pageContext }) => {
  const post = data.markdownRemark;
  const instagramPosts = data.allInstagramContent.edges;
  const { previous, next } = pageContext;

  return (
    <Layout instagramPosts={instagramPosts}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article className={styles.BlogPost}>
        <header>
          <h1 className={styles.BlogPost__header}>{post.frontmatter.title}</h1>
          <p className={styles.BlogPost__date}>{post.frontmatter.date}</p>
        </header>
        <section
          className={styles.BlogPost__content}
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </article>

      <nav className={styles.BlogPost__nav}>
        <ul>
          <li>
            {previous && (
              <Link
                to={previous.fields.slug}
                rel="prev"
                className={styles.BlogPost__prev}
              >
                <span>←</span> {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link
                to={next.fields.slug}
                rel="next"
                className={styles.BlogPost__next}
              >
                {next.frontmatter.title} <span>→</span>
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }

    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "DD.MM.YYYY")
        description
      }
    }

    allInstagramContent(limit: 8) {
      edges {
        node {
          id
          caption
          permalink # will be omitted if the Media contains copyrighted material, or has been flagged for a copyright violation
          media_url
          localImage {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                width: 500
                height: 500
              )
            }
          }
        }
      }
    }
  }
`;
